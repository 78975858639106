import { createRouter, createWebHistory, createWebHashHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/Dashboard.vue"),
        meta: {
          title: 'Dashboard',
          requireAuth: true,
        }
      },

      // Produksi
      {
        path: "/produksi/product_raw",
        name: "product_raw",
        component: () => import("@/views/produksi/product_raw/ProductRaw.vue"),
        meta: {
          title: 'Produk Raw',
          requireAuth: true,
        }
      },
      {
        path: "/produksi/product_raw/create_raw_desain",
        name: "create_raw_desain",
        component: () => import("@/views/produksi/product_raw/CreateRawDesain.vue"),
        meta: {
          title: 'Create Raw Desain',
          requireAuth: true,
        }
      },
      {
        path: "/produksi/product",
        name: "product",
        component: () => import("@/views/produksi/product/Product.vue"),
        meta: {
          title: 'Produk Jadi',
          requireAuth: true,
        }
      },
      {
        path: "/produksi/product/:tipe_produk/:kode",
        name: "product_detail",
        component: () => import("@/views/produksi/product/ProductDetail.vue"),
        meta: {
          title: route => `Produk Jadi - ${route.params.kode} (${route.params.tipe_produk})`,
          requireAuth: true,
        }
      },
      {
        path: "/produksi/product_material",
        name: "product_material",
        component: () => import("@/views/produksi/ProductMaterial.vue"),
        meta: {
          title: 'Produk Material',
          requireAuth: true,
        }
      },
      {
        path: "/produksi/product_consignment",
        name: "product_consignment",
        component: () => import("@/views/produksi/product_consignment/Create.vue"),
        meta: {
          title: 'Produk Konsi',
          requireAuth: true,
        }
      },
      {
        path: "/produksi/purchase_order",
        name: "purchase_order",
        component: () => import("@/views/produksi/PurchaseOrder.vue"),
        meta: {
          title: 'Purchase Order',
          requireAuth: true,
        }
      },
      {
        path: "/produksi/barcode_generator",
        name: "barcode_generator",
        component: () => import("@/views/produksi/barcode_generator/BarcodeGenerator.vue"),
        meta: {
          title: 'Barcode Generator',
          requireAuth: true,
        }
      },  

      // Inventory
      {
        path: "/inventory/stok_item",
        name: "stok_item",
        component: () => import("@/views/inventory/item/StokItem.vue"),
        meta: {
          title: 'Stok Item',
          requireAuth: true,
        }
      },      
      {
        path: "/inventory/stok_item/:kode",
        name: "stok_item_detail",
        component: () => import("@/views/inventory/item/StokItemDetail.vue"),
        meta: {
          title: route => `Stok Item - ${route.params.kode.split('-')[2].toUpperCase()}`,
          requireAuth: true,
        }
      },
      {
        path: "/inventory/stok_item/history",
        name: "stok_item_history",
        component: () => import("@/views/inventory/item/StokItemHistory.vue"),
        meta: {
          title: 'Riwayat Stok Item',
          requireAuth: true,
        }
      },
      {
        path: "/inventory/stok_desain",
        name: "stok_desain",
        component: () => import("@/views/inventory/desain/StokDesain.vue"),
        meta: {
          title: 'Stok Desain',
          requireAuth: true,
        }
      },
      {
        path: "/inventory/stok_desain/history",
        name: "stok_desain_history",
        component: () => import("@/views/inventory/desain/StokDesainHistory.vue"),
        meta: {
          title: 'Riwayat Stok Desain',
          requireAuth: true,
        }
      },
      {
        path: "/inventory/product_receiving",
        name: "product_receiving",
        component: () => import("@/views/inventory/receiving/ProductReceiving.vue"),
        meta: {
          title: 'Penerimaan Barang',
          requireAuth: true,
        }
      },
      {
        path: "/inventory/receiving_detail/:kode_receiving",
        name: "receiving_detail",
        component: () => import("@/views/inventory/receiving/ReceivingDetail.vue"),
        meta: {
          title: 'Detail Penerimaan',
          requireAuth: true,
        }
      },
      {
        path: "/inventory/create_receiving",
        name: "create_receiving",
        component: () => import("@/views/inventory/receiving/CreateReceiving.vue"),
        meta: {
          title: 'Buat Penerimaan Barang',
          requireAuth: true,
        }
      },

      // Distribusi
      {
        path: "/distribusi",
        name: "distribusi",
        component: () => import("@/views/distribusi/Distribusi.vue"),
        meta: {
          title: 'Distribusi',
          requireAuth: true,
        }
      },
      {
        path: "/distribusi/detail/:kode",
        name: "distribusi_detail",
        component: () => import("@/views/distribusi/DistribusiDetail.vue"),
        meta: {
          title: route => `Distribusi - ${route.params.kode}`,
          requireAuth: true,
        }
      },
      {
        path: "/distribusi/history",
        name: "distribusi_history",
        component: () => import("@/views/distribusi/DistribusiHistory.vue"),
        meta: {
          title: 'Riwayat Distribusi',
          requireAuth: true,
        }
      },

      // Return
      {
        path: "/return",
        name: "return",
        component: () => import("@/views/return/Return.vue"),
        meta: {
          title: 'Return Product',
          requireAuth: true,
        }
      },
      {
        path: "/return/detail/:kode_return",
        name: "return_detail",
        component: () => import("@/views/return/ReturnDetail.vue"),
        meta: {
          title: route => `Return Detail - ${route.params.kode_return}`,
          requireAuth: true,
        }
      },


      // Display
      {
        path: "/display",
        name: "display",
        component: () => import("@/views/display/Display.vue"),
        meta: {
          title: 'Display List',
          requireAuth: true,
        }
      },

      // Bank Desain
      {
        path: "/desain",
        name: "desain",
        component: () => import("@/views/desain/Desain.vue"),
        meta: {
          title: 'Bank Design List',
          requireAuth: true,
        }
      },

      // Supplier
      {
        path: "/supplier/request_po",
        name: "request_po",
        component: () => import("@/views/supplier/RequestPO.vue"),
        meta: {
          title: 'Request Purchase Order',
          requireAuth: true,
        }
      },
      {
        path: "/supplier/request_po_detail/:kode_request_po",
        name: "request_po_detail",
        component: () => import("@/views/supplier/_RequestDetail.vue"),
        meta: {
          title: 'Request Detail',
          requireAuth: true,
        }
      },
      {
        path: "/supplier/edit_request/:kode_request_po",
        name: "edit_request",
        component: () => import("@/views/supplier/EditRequest.vue"),
        meta: {
          title: 'Edit Request',
          requireAuth: true,
        }
      },
      {
        path: "/supplier/create_request",
        name: "create_request",
        component: () => import("@/views/supplier/CreateRequest.vue"),
        meta: {
          title: 'Create Request',
          requireAuth: true,
        }
      },
      {
        path: "/supplier/purchase_order",
        name: "purchase_order",
        component: () => import("@/views/supplier/PurchaseOrder.vue"),
        meta: {
          title: 'Purchase Order',
          requireAuth: true,
        }
      },
      {
        path: "/supplier/purchase_order_detail/:kode_po",
        name: "purchase_order_detail",
        component: () => import("@/views/supplier/_PurchaseOrderDetail.vue"),
        meta: {
          title: 'Purchase Order Detail',
          requireAuth: true,
        }
      },

      // Laporan
      {
        path: "/laporan/penjualan",
        name: "laporan_penjualan",
        component: () => import("@/views/laporan/penjualan/LaporanPenjualan.vue"),
        meta: {
          title: 'Laporan Penjualan',
          requireAuth: true,
        }
      },
      {
        path: "/laporan/penjualan/summary",
        name: "summary_penjualan",
        component: () => import("@/views/laporan/penjualan/SummaryPenjualan.vue"),
        meta: {
          title: 'Ringkasan Penjualan Semua Waktu',
          requireAuth: true,
        }
      },
      {
        path: "/laporan/laba_rugi",
        name: "laporan_laba_rugi",
        component: () => import("@/views/laporan/pnl/LaporanLabaRugi.vue"),
        meta: {
          title: 'Laporan Laba Rugi',
          requireAuth: true,
        }
      }, 
      {
        path: "/laporan/laba_rugi/summary",
        name: "summary_laba_rugi",
        component: () => import("@/views/laporan/pnl/SummaryLabaRugi.vue"),
        meta: {
          title: 'Ringkasan Laba Rugi Semua Waktu',
          requireAuth: true,
        }
      }, 
      {
        path: "/laporan/stok",
        name: "laporan_stok",
        component: () => import("@/views/laporan/LaporanStok.vue"),
        meta: {
          title: 'Laporan Stok',
          requireAuth: true,
        }
      },
      {
        path: "/laporan/desain",
        name: "laporan_desain",
        component: () => import("@/views/laporan/LaporanDesain.vue"),
        meta: {
          title: 'Laporan Desain',
          requireAuth: true,
        }
      },
      {
        path: "/laporan/packinglist",
        name: "laporan_packinglist",
        component: () => import("@/views/laporan/LaporanPackingList.vue"),
        meta: {
          title: 'Sinkron Packing List',
          requireAuth: true,
        }
      },

      // Master
      {
        path: "/master/store",
        name: "store",
        component: () => import("@/views/master/Store.vue"),
        meta: {
          title: 'Master Toko',
          requireAuth: true,
        }
      }, 
      {
        path: "/master/price",
        name: "price",
        component: () => import("@/views/master/Price.vue"),
        meta: {
          title: 'Master Harga',
          requireAuth: true,
        }
      },      
      {
        path: "/master/category",
        name: "category",
        component: () => import("@/views/master/Category.vue"),
        meta: {
          title: 'Master Kategori',
          requireAuth: true,
        }
      },      
      {
        path: "/master/color",
        name: "color",
        component: () => import("@/views/master/Color.vue"),
        meta: {
          title: 'Master Warna',
          requireAuth: true,
        }
      },
      {
        path: "/master/size",
        name: "size",
        component: () => import("@/views/master/Size.vue"),
        meta: {
          title: 'Master Ukuran',
          requireAuth: true,
        }
      },
      {
        path: "/master/print_size",
        name: "print_size",
        component: () => import("@/views/master/PrintSize.vue"),
        meta: {
          title: 'Master Ukuran Print',
          requireAuth: true,
        }
      },  

      // Discount
      {
        path: "/discount/product_voucher",
        name: "product_voucher",
        component: () => import("@/views/discount/ProductVoucher.vue"),
        meta: {
          title: 'List Diskon Produk',
          requireAuth: true,
        }
      },

      // User
      {
        path: "/user_management",
        name: "user_management",
        component: () => import("@/views/users/User.vue"),
        meta: {
          title: 'User Management',
          requireAuth: true,
        }
      },
      {
        path: "/user_management/:user_id",
        name: "user_detail",
        component: () => import("@/views/users/UserDetail.vue"),
        meta: {
          title: 'User Detail',
          requireAuth: true,
        }
      },

      // Menu
      {
        path: "/menu/menu_management",
        name: "menu_management",
        component: () => import("@/views/menu/Menu.vue"),
        meta: {
          title: 'Menu Management',
          requireAuth: true,
        }
      },
      {
        path: "/menu/group_menu",
        name: "group_menu",
        component: () => import("@/views/menu/GroupMenu.vue"),
        meta: {
          title: 'Groups Menu',
          requireAuth: true,
        }
      },
    ],
  },
  
  {
    path: "/produksi/barcode_generator/print_barcode",
    name: "print_barcode",
    component: () => import("@/views/produksi/barcode_generator/PrintBarcode.vue"),
    meta: {
      title: 'Print Barcode',
      requireAuth: true,
    }
  },
  {
    path: "/distribusi/print/:kode",
    name: "distribusi_download",
    component: () => import("@/views/distribusi/Print.vue"),
    meta: {
      title: 'Download Distribusi',
      requireAuth: true,
    }
  },
  {
    path: "/supplier/print_purchase_order/:kode_po",
    name: "print_purchase_order",
    component: () => import("@/views/supplier/PrintPurchaseOrder.vue"),
    meta: {
      title: 'Print Purchase Order',
      requireAuth: true,
    }
  },

  // Auth
  {
    path: "/",
    component: () => import("@/views/auth/Auth.vue"),
    children: [
      {
        path: "/auth/login",
        name: "login",
        meta: {
          title: 'Login',
          requireAuth: false,
        },
        component: () => import("@/views/auth/Login.vue"),
        beforeEnter: (to, from, next) => { 
          if (JwtService.getToken()) {
            next('/');
          } else {
            next();
          }
        },
      },
    ],
  },

  // Error
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/Error404.vue"),
  },
  {
    path: "/500",
    name: "500",
    component: () => import("@/views/Error500.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async(to, from, next) => {
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  const { title } = to.meta;
  document.title = typeof title === 'function' ? title(to) : 'Nimco - '+title;

  /**
   * @description verifying auth to back-end
   */
  if (to.name != 'login') {
    await store.dispatch(Actions.VERIFY_AUTH, { token: JwtService.getToken()});
    const isAuthenticated = await store.getters.isUserAuthenticated

    /**
     * @description checking auth vuex
     */

    if(to.meta.requireAuth == true) {
      if (isAuthenticated) {
        // Routing page according to user role

      } else {
        return next({name: 'login'})
      }
    }
  }


  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);

  next()
  
});

export default router;
